@font-face {
  font-family: 'NeueMontreal';
  font-weight: 200;
  src: url('./assets/fonts/NeueMontreal/NeueMontreal-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 400;
  src: url('./assets/fonts/NeueMontreal/NeueMontreal-Book.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 530;
  src: url('./assets/fonts/NeueMontreal/NeueMontreal-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 800;
  src: url('./assets/fonts/NeueMontreal/NeueMontreal-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 700;
  src: url('./assets/fonts/NeueMontreal/NeueMontreal-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'VictorSerifSmooth';
  font-weight: 500;
  src: url('./assets/fonts/VictorSerifSmooth/VictorSerifSmooth-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'VictorSerifSmooth';
  font-weight: normal;
  src: url('./assets/fonts/VictorSerifSmooth/VictorSerifSmooth-Regular.otf') format('opentype');
}



body {
  margin: 0;
  font-family: NeueMontreal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255, 255, 255);
}

button,
input,
optgroup,
select,
textarea {
  font-family: NeueMontreal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}


.tf-dropwdown-menu .MuiList-root.MuiMenu-list .MuiMenuItem-root {
  background: white;
  margin: 0.5rem 0;
  border-radius: 4px;
}

.tf-dropwdown-menu .MuiList-root.MuiMenu-list .MuiMenuItem-root:hover {
  opacity: 1;
  filter: brightness(0.95);
}

.tf-dropwdown-menu .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  background: transparent;
  position: fixed;
  z-index: 1400;
}

.tf-dropwdown-menu .MuiBackdrop-root {
  background: rgb(0 0 0 / 43%);
  position: fixed;
  z-index: 1200;
}

.tf-dropwdown-menu .MuiMenuItem-root+.MuiDivider-root {
  border-color: #b3aeae;
}

.tf-dropwdown-menu.MuiPopover-root {
  z-index: initial;
  position: relative;
}

.App {
  min-height: 100vh;
}